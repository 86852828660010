import { Link } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';
import SEO from '../components/seo';
import LandingLayout from '../layouts/landing';

const HeroContainer = tw.div`py-24 md:py-28 lg:py-36 bg-hero-pattern text-center`;
const HeroInnerContainer = tw.div`flex flex-col items-center max-w-screen-lg mx-auto px-6`;
const HeroTitle = tw.div`text-3xl md:text-5xl lg:text-7xl font-bold`;
const HeroSubtitle = tw.div`pt-6 md:pt-10 text-gray-600 text-base md:text-xl`;
const HeroDivider = tw.div`my-8 md:my-12 w-20 h-3 md:h-5 bg-purple-300`;

const ButtonStyle = `inline-block px-4 md:px-6 py-2 md:py-3 shadow border-2 border-purple-600 rounded text-base md:text-lg font-semibold hover:(bg-cta-texture animate-marquee)`;
const CTA = tw.div`flex items-center`;
const PrimaryButton = tw.a`${ButtonStyle} bg-purple-600 text-white`;
const Button = tw(Link)`
${ButtonStyle} bg-transparent text-purple-600 hover:(bg-purple-600 text-white)
`;
const LinkButton = tw(Link)`ml-4 text-purple-600 text-lg font-semibold hover:underline`;

const SectionContainer = tw.div`px-6 pt-8 md:pt-12 max-w-screen-lg mx-auto`;
const SectionTitle = tw.div`pb-2 md:pb-3 text-2xl md:text-4xl text-black font-bold`;
const SectionBody = tw.div`mt-2 md:mt-3 text-base md:text-lg text-gray-600 font-light`;

const ServiceContainer = tw.div`grid grid-cols-2 md:grid-cols-4 gap-5 max-w-screen-lg mx-auto mt-12 px-6`;
const ServiceItem = tw.div`relative px-4 py-6 md:px-6 md:py-8 bg-gradient-to-br from-indigo-50 to-purple-100 text-sm md:text-base`;
const ServiceTitle = tw.div`absolute -left-1 -top-3 mb-2 px-2 py-1 text-base md:text-lg text-white bg-black font-bold`;
const ServiceIcon = tw.img`absolute h-2/3 bottom-2 right-2 opacity-10`;

const PartnerContainer = tw.div`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 max-w-screen-lg mx-auto mt-8 px-6`;
const PartnerItem = tw.div`flex items-center justify-center h-24 sm:h-28 md:h-32`;
const PartnerImage = tw.img`px-2 max-h-full`;

const NoticeContainer = tw.section`mt-8 py-8 md:py-12 bg-gradient-to-br from-indigo-200 to-purple-200 text-center`;
const Notice = tw.div`pb-4 md:pb-6 text-purple-700 text-xl md:text-3xl font-bold`;

const ServiceData = [
  {
    icon: '/service/website.svg',
    title: 'Web 开发',
    body: '网站建设 / DevOps 实施 / 信息化系统 / SaaS',
  },
  {
    icon: '/service/mini-app.svg',
    title: '小程序',
    body: '微信小程序 / 微信平台营销 / 企业微信 / 钉钉',
  },
  {
    icon: '/service/app.svg',
    title: '应用开发',
    body: '移动端 App / 桌面端应用 / 智能合约',
  },
  {
    icon: '/service/si.svg',
    title: '系统集成',
    body: '网络架构 / SDK / 中间件 / ERP / CRM / WMS',
  },
  {
    icon: '/service/design.svg',
    title: '设计',
    body: 'UI 设计 / VI 建设 / UX 流程 / 视频动效',
  },
  {
    icon: '/service/ai.svg',
    title: '大数据',
    body: '数据挖掘 / 数据仓库 / 数据可视化 / AI 算法',
  },
  {
    icon: '/service/iot.svg',
    title: '物联网',
    body: '智能硬件 / MCU / IoT 平台',
  },
  {
    icon: '/service/security.svg',
    title: '咨询',
    body: '数字化转型 / 信息安全 / 测试',
  },
];
const PartnerData = [
  { name: '易桥云税', logo: '/partner/yqyunshui.svg' },
  { name: '中国联通', logo: '/partner/chinaunicom.svg' },
  { name: '达康教育', logo: '/partner/dakang-edu.svg' },
  { name: '齐装网', logo: '/partner/qizhuang.svg' },
  { name: '美团买菜', logo: '/partner/maicai.meituan.svg' },
  { name: 'Sanofi', logo: '/partner/sanofi.svg' },
  { name: '世茂集团', logo: '/partner/shimaogroup.svg' },
  { name: '雄安盈家', logo: '/partner/zuzhu.tech.svg' },
  { name: '星巴克', logo: '/partner/starbucks.svg' },
  { name: 'XTransfer', logo: '/partner/xtransfer.svg' },
];

export default () => {
  return (
    <LandingLayout>
      <SEO title="无矩，助力您数字化浪潮之旅" titleTemplate="%s" />
      <HeroContainer>
        <HeroInnerContainer>
          <HeroTitle>
            无矩，助力您<span className="strong">数字化</span>浪潮之旅
          </HeroTitle>
          <HeroSubtitle>效率 / 未来 / 科技 / 智能 / 影响力</HeroSubtitle>
          <HeroDivider />
          <CTA>
            <PrimaryButton
              href="https://wj.qq.com/s2/9517779/bd18/"
              target="_blank"
              rel="noopener noreferrer"
            >
              提交需求，马上开工
            </PrimaryButton>
            &nbsp;&nbsp;
            <LinkButton to="/consult">在线咨询 →</LinkButton>
          </CTA>
        </HeroInnerContainer>
      </HeroContainer>
      <SectionContainer>
        <SectionTitle>
          我们<span className="strong">能做什么</span>？
        </SectionTitle>
        <SectionBody>我们作为技术供应商，主要向合作伙伴提供软件产品的开发与维护服务。</SectionBody>
        <SectionBody>
          应对不同企业的管理模式与信息化需求，采用数字化手段降本增效，或者拓展全新的商业模式。
        </SectionBody>
      </SectionContainer>
      <ServiceContainer>
        {ServiceData.map((o) => (
          <ServiceItem key={o.title}>
            <ServiceIcon src={o.icon} />
            <ServiceTitle>{o.title}</ServiceTitle>
            {o.body}
          </ServiceItem>
        ))}
      </ServiceContainer>
      <SectionContainer>
        <SectionTitle>
          我们<span className="strong">为谁提供服务</span>？
        </SectionTitle>
        <SectionBody>我们的技术不限于某一行业的应用；我们的合作伙伴产业覆盖多个领域。</SectionBody>
        <SectionBody>我们以共同成功为目标，交付高质量、易用、长期维护的综合解决方案。</SectionBody>
      </SectionContainer>
      <PartnerContainer>
        {PartnerData.map((o) => (
          <PartnerItem key={o.name}>
            <PartnerImage alt={o.name} src={o.logo} />
          </PartnerItem>
        ))}
      </PartnerContainer>
      <NoticeContainer>
        <Notice>您需要一个了解您业务的技术伙伴？</Notice>
        <Button to="/consult">联系我们 →</Button>
      </NoticeContainer>
    </LandingLayout>
  );
};
